import React from "react";
import { Card, Container, Row, Col, CardBody, CardTitle } from "reactstrap";

export default function Team4({ title, description, members }) {
	return (
		<div className="team-4">
			<Container>
				<Row>
					<Col className="ml-auto mr-auto text-center" md="8">
						{title ? <h2 className="title">{title}</h2> : null}
						{description ? (
							<h4 className="description">{description}</h4>
						) : null}
					</Col>
				</Row>
				<Row>
					{members.map((member, i) => (
						<Member key={i} {...member} />
					))}
				</Row>
			</Container>
		</div>
	);
}

function Member({ name, title, image, description }) {
	return (
		<Col md="6">
			<Card className="card-profile card-plain">
				<Row>
					<Col md="5">
						<div className="card-image">
							<img
								alt="..."
								className="img img-raised rounded"
								src={image}
							/>
						</div>
					</Col>
					<Col md="7">
						<CardBody>
							<CardTitle tag="h4">{name}</CardTitle>
							{title ? (
								<h6 className="category">{title}</h6>
							) : null}
							<p className="card-description">{description}</p>
						</CardBody>
					</Col>
				</Row>
			</Card>
		</Col>
	);
}
