import React from "react";
import { Container, Row, Col } from "reactstrap";
import MarkdownView from "react-showdown";

export default function Product1({
	title,
	subtitle,
	price,
	quote,
	quoteSource,
	description,
	image,
}) {
	return (
		<Container>
			<Row>
				<Col md="5">
					<img
						src={image}
						alt=""
						className="d-block img-raised"
						style={{ marginTop: -90 }}
					/>
					{quote ? (
						<p className="blockquote blockquote-info">
							{quote}
							<br />
							<br />
							{quoteSource ? <small>{quoteSource}</small> : null}
						</p>
					) : null}
				</Col>
				<Col className="ml-auto mr-auto" md="6">
					<h2 className="title">{title}</h2>
					{subtitle ? <h5 className="category">{subtitle}</h5> : null}
					{price ? <h2 className="main-price">{price}</h2> : null}
					{description ? (
						<MarkdownView markdown={description} />
					) : null}
				</Col>
			</Row>
		</Container>
	);
}
