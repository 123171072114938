import React from "react";
import { Container, Row, Col } from "reactstrap";
import MarkdownView from "react-showdown";

export default function Features5({ title, image, features }) {
	return (
		<div className="features-5">
			<Container>
				<Row>
					<Col md="5">
						<h2 className="title">{title}</h2>
						{features.map((feature, i) => (
							<Feature key={i} {...feature} />
						))}
					</Col>
					<Col md="7">
						<div className="tablet-container">
							<img alt="" src={image} />
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

function Feature({ title, icon, text }) {
	return (
		<div className="info info-horizontal">
			<div className="icon icon-info icon-circle">
				<i className={`now-ui-icons ${icon}`} />
			</div>
			<div className="description">
				<h4 className="info-title">{title}</h4>
				<MarkdownView markdown={text} />
			</div>
		</div>
	);
}
